import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import {
  Button,
  Columns,
  Container,
  Element,
  Form,
  Heading,
  Icon,
  Loader,
  Section,
  Table,
} from 'react-bulma-components'
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import SingleProductThumbnail from '../components/e-commerce/single-product-thumbnail'
import { useProducts } from '../stores/products'
import FileIcon from '../components/icons/file'
import { useSession } from '../stores/session'
import ProductQuantitySelector from '../components/products/quantity-selector'
import ProductPrices from '../components/products/prices'
import AttemptVote from '../components/products/votes'
import { sortBy } from 'lodash'
import { Carousel } from 'react-responsive-carousel'
import striptags from 'striptags'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import ProductStock from '../components/products/stock'

const isBrowser = typeof window !== 'undefined'

const Product = ({ pageContext, data }) => {
  const { language } = pageContext
  const { products: product } = data
  const { navigate } = useI18next()
  const [state, actions] = useProducts()

  const [sessionState, sessionActions] = useSession()

  const { t } = useTranslation()

  React.useEffect(() => {
    actions.get(product._id)
  }, [product])

  const breadcrumbs = React.useMemo(
    () =>
      [
        {
          label: product.brand.name,
          onClick: e => {
            e.stopPropagation()
            actions.setFilter({
              key: 'brand',
              value: [product.brand._id],
              clear: true,
            })
            navigate('/all-products')
          },
        },
        product.categories[0] && {
          label: product.categories[0].labels[language],
          onClick: e => {
            e.stopPropagation()
            actions.setFilter({
              key: 'categories',
              value: [product.categories[0]._id],
              clear: true,
            })
            navigate('/all-products')
          },
        },
        {
          label: product.name[language],
          to: pageContext.pageUrl,
        },
      ].filter(i => i),
    [product, actions.setFilter]
  )
  const [linkedProducts, setLinkedProducts] = React.useState([])

  const fetchLinkedProducts = React.useCallback(async () => {
    if (product && product.linkedProducts && product.linkedProducts[0]) {
      setLinkedProducts(
        await actions.getProducts(product.linkedProducts.map(p => p && p._id))
      )
    } else {
      setLinkedProducts([])
    }
  }, [product])

  React.useEffect(() => {
    fetchLinkedProducts()
  }, [product])

  const files = React.useMemo(() => {
    if (!isBrowser || !sessionState.isAuthenticated)
      return product.files.filter(file => file.visibility.public)
    return product.files.filter(file => {
      if (!isBrowser || !sessionState.isAuthenticated)
        return file.visibility.public
      else if (
        sessionState.isAuthenticated &&
        sessionState.currentOrganization &&
        sessionState.currentOrganization.role === 'organization/commercials'
      )
        return file.visibility.commercials || file.visibility.public
      else if (sessionState.isAuthenticated && sessionState.currentOrganization)
        return (
          file.visibility.professionals ||
          file.visibility.public ||
          file.visibility.commercials
        )
    })
  }, [
    product,
    isBrowser,
    sessionState.isAuthenticated,
    sessionState.currentOrganization,
  ])

  const vote = React.useCallback(
    async score => {
      await actions.attemptVote({
        productId: product._id,
        score,
      })
    },
    [product]
  )

  const attributesSections = React.useMemo(() => {
    let sections = []
    const others = []
    product.attributes.forEach(attribute => {
      const indexOfSection = attribute.item.attribute
        ? sections.findIndex(s => s._id === attribute.item.attribute._id)
        : -1
      if (attribute.item.attribute && indexOfSection === -1)
        sections.push({ ...attribute.item.attribute, items: [attribute] })
      else if (attribute.item.attribute)
        sections[indexOfSection].items.push(attribute)
      else others.push(attribute)
    })

    sections = sortBy(sections, 'positionIndex')
    if (others[0])
      sections.push({
        _id: 'other',
        labels: { fr: 'Autres', en: 'Other', nl: 'Other' },
        items: others,
      })
    return sections
  }, [product])

  //if (!product || product._id !== pageContext.product._id) return <></>
  const seoProductName =
    product && product.name && product.name[language]
      ? product.name[language]
      : ''
  const seoProductDescription =
    product && product.description && product.description[language]
      ? product.description[language]
      : ''
  const seoTitle = `Exalto - ${seoProductName}`

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Seo title={seoTitle} />
      <Section>
        <Container>
          <Columns multiline centered>
            <Columns.Column size={12} mb={5}>
              <Heading
                renderAs='h1'
                textColor='black'
                size={3}
                textTransform='uppercase'
                textWeight='light'
                mb={4}
              >
                {product.name[language]}
              </Heading>
              <Heading renderAs='p' subtitle size={7}>
                <ul className='single-product-header-subtitle'>
                  <li>{product.mafactData && product.mafactData.ref}</li>
                  <li>
                    {t('products:brand')} :&nbsp;
                    <a
                      onClick={() => {
                        actions.setFilter({
                          key: 'brand',
                          value: [product.brand._id],
                          clear: true,
                        })
                        navigate('/all-products')
                      }}
                    >
                      {product.brand.name}
                    </a>
                  </li>
                </ul>
              </Heading>
            </Columns.Column>
            <Columns.Column
              size={8}
              display='flex'
              flexDirection='column'
              alignItems='center'
            >
              <Carousel
                showIndicators={false}
                showStatus={false}
                useKeyboardArrows
                infiniteLoop
                showThumbs={Boolean(product.pictures && product.pictures[1])}
              >
                {product.pictures.map((picture, index) => (
                  <div key={picture._id}>
                    <img
                      src={`https://res.cloudinary.com/dkgryqpim/image/upload/c_pad,h_700,w_700/q_auto/${picture.item.providerId}.jpg`}
                    />
                  </div>
                ))}
              </Carousel>
            </Columns.Column>
            <Columns.Column size={3}>
              <Heading
                renderAs='h2'
                textColor='black'
                size={4}
                textTransform='uppercase'
                textWeight='light'
                mb={4}
              >
                {product.name[language]}
              </Heading>
              <Heading subtitle size={7} renderAs='p'>
                <ul className='single-product-header-subtitle'>
                  <li>{product.mafactData && product.mafactData.ref}</li>
                </ul>
              </Heading>
              <Element mb={2} renderAs='ul' textSize='7' textWeight='bold'>
                <li>
                  {t('products:brand')}:&nbsp;
                  <a
                    onClick={() => {
                      actions.setFilter({
                        key: 'brand',
                        value: [product.brand._id],
                        clear: true,
                      })
                      navigate('/all-products')
                    }}
                  >
                    {product.brand.name}
                  </a>
                </li>
                <li>
                  {t('products:category')}:{' '}
                  {product.categories.map((category, index) => (
                    <span key={category._id}>
                      <a
                        onClick={() => {
                          actions.setFilter({
                            key: 'categories',
                            value: [category._id],
                            clear: true,
                          })
                          navigate('/all-products')
                        }}
                      >
                        <span>{category.labels[language]}</span>
                      </a>
                      {index !== product.categories.length - 1 && ', '}
                    </span>
                  ))}
                </li>
              </Element>
              {product.description && (
                <Element
                  renderAs='div'
                  textSize='7'
                  dangerouslySetInnerHTML={{
                    __html: product.description[language || 'fr'],
                  }}
                />
              )}

              <Element display='flex' justifyContent='space-between' mt={4}>
                {product.kind === 'article' ? (
                  <Element display='flex' flexDirection='column' justifyContent='space-between'>
                    <ProductPrices
                      product={product}
                      customPrices={
                        product &&
                        state.allPrices &&
                        sessionState.isAuthenticated &&
                        state.allPrices[product._id]
                      }
                    />
                  </Element>
                ) : (
                  <Element>
                    { state.current && <AttemptVote
                      product={state.current}
                      currentUser={sessionState.currentUser}
                      vote={vote}
                    />}

                  </Element>
                )}
                <Element display='flex' flexDirection='column' justifyContent='space-between' alignItems='end' mb={1}>
                  <Element mb={2}>
                    {t('products:in_stock')} :&nbsp;
                    <Element
                      renderAs='span'
                      mb={2}
                      textColor={
                        state.current &&
                        state.current.stock.available &&
                      'success'
                      }
                    >
                      {state.current ?
                        <ProductStock currentUser={sessionState.currentUser} stock={state.current.stock.available} />
                        :
                        <Loader style={{ display: 'inline-block' }} />
                      }
                      &nbsp;
                    </Element>
                  </Element>

                  <Form.Field kind='addons' alignItems='end'>
                    <ProductQuantitySelector
                      basket={sessionState.basket}
                      productId={product._id}
                      onChange={sessionActions.addItemToBasket}
                    />
                  </Form.Field>
                </Element>
              </Element>
              {product.kind === 'article' && (
                <Element mt={4}>
                  <Table.Container>
                    <Table striped hoverable size='fullwidth'>
                      <Element renderAs='p' textSize='7'>
                        Recupel:{' '}
                        {product.pricing.recupel
                          ? `${product.pricing.recupel} €`
                          : '0 €'}{' '}
                        {product.pricing.recupelCode && sessionState.currentUser &&
                          `(${product.pricing.recupelCode})`}
                      </Element>
                      <Element renderAs='p' textSize='7'>
                        Auvibel:{' '}
                        {product.pricing.auvibel
                          ? `${product.pricing.auvibel} €`
                          : '0 €'}{' '}
                      </Element>
                      <Element renderAs='p' textSize='7'>
                        Bebat:{' '}
                        {product.pricing.bebat
                          ? `${product.pricing.bebat} €`
                          : '0 €'}{' '}
                        {product.pricing.bebatCode && sessionState.currentUser &&
                          `(${product.pricing.bebatCode})`}
                        {product.pricing.bebatBatteryCode && sessionState.currentUser &&
                          `(${product.pricing.bebatBatteryCode})`}
                      </Element>
                    </Table>
                  </Table.Container>
                </Element>
              )}
              {sessionState.currentUser && product.kind === 'article' && (
                <Element mt={4}>
                  <Table.Container>
                    <Table striped hoverable size='fullwidth'>
                      <Element renderAs='p' textSize='7'>
                        EAN: {product.mafactData.codebar}
                      </Element>
                      <Element renderAs='p' textSize='7'>
                        {t('products:piece_per_carton')}:{' '}
                        {product.packaging.piecePerCardboard}
                      </Element>
                    </Table>
                  </Table.Container>
                </Element>
              )}
            </Columns.Column>
          </Columns>
          <Columns gap={8} mt={6}>
            <Columns.Column size={8}>
              {/*<Heading size={5} mb={5}>{t('products:technical_specifications')}</Heading>*/}
              {attributesSections.map(section => (
                <Element key={section._id} mb={6}>
                  <Heading size={5} mb={2} renderAs='h2'>
                    {section.labels[language || 'fr']}
                  </Heading>
                  <Table.Container>
                    <Table striped hoverable size='fullwidth'>
                      <tbody>
                        {sortBy(section.items, language).map(attribute => (
                          <tr key={attribute._id}>
                            <td style={{ width: '50%' }}>
                              {attribute[language]}
                            </td>
                            <td style={{ width: '50%' }}>
                              {attribute.value[language] && attribute.value[language].match(/^Oui( - )?/ig) ?
                                <><FontAwesomeIcon icon={faCheck} /> {attribute.value[language].replace(/^Oui( - )?/ig, '')}</> :
                                attribute.value[language]}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Table.Container>
                </Element>
              ))}
            </Columns.Column>
            <Columns.Column>
              {files && files[0] && (
                <>
                  <Heading size={5} mb={5} renderAs='h2'>
                    {t('products:attached_files')}
                  </Heading>
                  {files.map(file => (
                    <Element
                      display='flex'
                      justifyContent='space-between'
                      key={file._id}
                      mb={2}
                    >
                      <Heading size={6} textColor='dark' marginless>
                        {file.name[language]}&nbsp;
                        <Icon color='primary'>
                          <FileIcon fileName={file.path} />
                        </Icon>
                      </Heading>
                      <Button
                        color='primary'
                        size='small'
                        outlined
                        href={`${process.env.API_HOST}/products/${product._id}/files/${file._id}`}
                        target='__blank'
                        renderAs='a'
                      >
                        {t('products:download_attached_file')}
                      </Button>
                    </Element>
                  ))}
                </>
              )}
            </Columns.Column>
          </Columns>
          {linkedProducts && linkedProducts[0] && (
            <Columns mt={6} mb={6}>
              <Columns.Column
                size={12}
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                <Heading size={5} mb={5}>
                  {t('products:linked_products')}
                </Heading>
              </Columns.Column>
              {linkedProducts.map(linkedProduct => (
                <Columns.Column key={linkedProduct._id} size={3}>
                  <SingleProductThumbnail
                    index={5}
                    product={linkedProduct}
                    small
                  />
                </Columns.Column>
              ))}
            </Columns>
          )}
        </Container>
      </Section>
    </Layout>
  )
}
export default Product

export const query = graphql`
  query productsAndLocale($language: String!, $productId: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    products(_id: { eq: $productId }) {
      _id
      brand {
        _id
        name
        logo
        link
        visibility {
          public
        }
      }
      votes {
        _id
        user
        score
        comment
      }
      attributes {
        en
        fr
        item {
          attribute {
            _id
            family
            positionIndex
            relational
            type
            labels {
              en
              fr
              nl
            }
            dates {
              created
            }
          }
          _id
          dates {
            created
          }
          family
          relational
          type
          values {
            en
            _id
            fr
            nl
          }
          labels {
            en
            fr
            nl
          }
        }
        nl
        value {
          en
          fr
          item
          nl
        }
      }
      categories {
        _id
        labels {
          en
          fr
          nl
        }
        alternative_parent {
          labels {
            en
            fr
            nl
          }
        }
      }
      description {
        en
        fr
        nl
      }
      files {
        _id
        name {
          en
          nl
          item
          fr
        }
        path
        visibility {
          commercials
          professionals
          public
        }
      }
      kind
      linkedProducts {
        _id
      }
      mafactData {
        category
        codebar
        name {
          fr
          nl
        }
        ref
        typart
        uid
      }
      name {
        en
        fr
        nl
      }
      packaging {
        baseUnit
        byCardboard
        byPallet
        customerUnit
        length
        surface
        unit
        volume
        weight
        piecePerCardboard
      }
      pictures {
        _id
        cover
        item {
          providerId
        }
      }
      pricing {
        auvibel
        bebat
        price
        recupel
        recupelCode
        auvibelCode
        bebatCode
        srp
        taxes
        vat
      }
      removedFromMafact
      stock {
        available
        clientOrders
        providerOrders
        total
      }
      visibility {
        professionals
        public
        homepage {
          newness
          promotion
        }
      }
    }
  }
`
